import * as Sentry from '@sentry/vue';

export function initSentry({app, router, sentrySettings, spaName}) {
    Sentry.setTag('spa', spaName);
    Sentry.init({
        app,
        integrations: [
            Sentry.browserTracingIntegration({
                router,
            }),
            Sentry.replayIntegration(),
        ],
        ...sentrySettings,
    });
}

export function setUserOnSentry(personId) {
    if (personId) {
        Sentry.setUser({
            id: `Person=${personId}`,
        });
    }
}

export function sendWarningToSentry(message) {
    Sentry.captureMessage(message, 'warning');
}
