export const CLOCKING_KIND = {
    IN: 'in',
    OUT: 'out',
    START_BREAK: 'start_break',
    END_BREAK: 'end_break',
    START_ABSENCE: 'start_absence',
    END_ABSENCE: 'end_absence',
    START_OVERTIME: 'start_overtime',
    END_OVERTIME: 'end_overtime',
};

export const CLOCKING_KIND_ICON_NAME = {
    [CLOCKING_KIND.IN]: 'arrow-right-to-arc',
    [CLOCKING_KIND.OUT]: 'arrow-right-from-arc',
    [CLOCKING_KIND.START_BREAK]: 'pause',
    [CLOCKING_KIND.END_BREAK]: 'play',
    [CLOCKING_KIND.START_ABSENCE]: 'pause',
    [CLOCKING_KIND.END_ABSENCE]: 'play',
    [CLOCKING_KIND.START_OVERTIME]: 'business-time',
    [CLOCKING_KIND.END_OVERTIME]: 'business-time',
};

export const NEXT_CLOCKING_KIND_ANIMATION_DEFAULT = 3;

export const NEXT_CLOCKING_KIND_ANIMATION = {
    [CLOCKING_KIND.IN]: 0,
    [CLOCKING_KIND.OUT]: 1,
    [CLOCKING_KIND.START_BREAK]: 1,
    [CLOCKING_KIND.END_BREAK]: 2,
    [CLOCKING_KIND.START_ABSENCE]: 1,
    [CLOCKING_KIND.END_ABSENCE]: 3,
    [CLOCKING_KIND.START_OVERTIME]: 3,
    [CLOCKING_KIND.END_OVERTIME]: 1,
};

export const CLOCKING_KIND_LABEL = {
    [CLOCKING_KIND.IN]: 'entrata',
    [CLOCKING_KIND.OUT]: 'uscita',
    [CLOCKING_KIND.START_BREAK]: 'inizio pausa',
    [CLOCKING_KIND.END_BREAK]: 'fine pausa',
    [CLOCKING_KIND.START_ABSENCE]: 'inizio assenza',
    [CLOCKING_KIND.END_ABSENCE]: 'fine assenza',
    [CLOCKING_KIND.START_OVERTIME]: 'inizio straordinario',
    [CLOCKING_KIND.END_OVERTIME]: 'fine straordinario',
};

export const ANOMALY_KIND = {
    CLOCKING_TIMING: 'CLOCKING_TIMING',
    WORKDAY_HOURS: 'WORKDAY_HOURS',
    MISSING: 'MISSING',
    UNEXPECTED: 'UNEXPECTED',
    MISSING_GEOLOCATION: 'MISSING_GEOLOCATION',
    GEOLOCATION_OUTSIDE_RANGE: 'GEOLOCATION_OUTSIDE_RANGE',
};

export const ANOMALY_KINDS = [
    ANOMALY_KIND.WORKDAY_HOURS,
    ANOMALY_KIND.CLOCKING_TIMING,
    ANOMALY_KIND.MISSING,
    ANOMALY_KIND.UNEXPECTED,
    ANOMALY_KIND.MISSING_GEOLOCATION,
    ANOMALY_KIND.GEOLOCATION_OUTSIDE_RANGE,
];

export const GEOLOCATION_ANOMALY_KINDS = [ANOMALY_KIND.MISSING_GEOLOCATION, ANOMALY_KIND.GEOLOCATION_OUTSIDE_RANGE];

export const NON_GEOLOCATION_ANOMALY_KINDS = ANOMALY_KINDS.filter(kind => !GEOLOCATION_ANOMALY_KINDS.includes(kind));

export const ANOMALY_KIND_DISPLAY_NAMES = {
    [ANOMALY_KIND.WORKDAY_HOURS]: 'Ore lavorate',
    [ANOMALY_KIND.CLOCKING_TIMING]: 'Orario non rispettato',
    [ANOMALY_KIND.MISSING]: 'Timbratura mancante',
    [ANOMALY_KIND.UNEXPECTED]: 'Timbratura inattesa',
    [ANOMALY_KIND.MISSING_GEOLOCATION]: 'Posizione mancante',
    [ANOMALY_KIND.GEOLOCATION_OUTSIDE_RANGE]: 'Distanza dalla sede',
};

export const ANOMALY_KIND_ICON_NAME = {
    [ANOMALY_KIND.WORKDAY_HOURS]: 'hourglass-half',
    [ANOMALY_KIND.CLOCKING_TIMING]: 'clock',
    [ANOMALY_KIND.MISSING]: 'circle-exclamation',
    [ANOMALY_KIND.UNEXPECTED]: 'circle-question',
    [ANOMALY_KIND.MISSING_GEOLOCATION]: 'location-dot-slash',
    [ANOMALY_KIND.GEOLOCATION_OUTSIDE_RANGE]: 'location-dot',
};

export const CLOCKING_DEVICE = {
    MOBILE: 'MOBILE',
    WEB: 'WEB',
    WEB_OR_MOBILE: 'WEB_OR_MOBILE',
};

export const CLOCKING_DEVICE_DISPLAY_NAMES = {
    [CLOCKING_DEVICE.MOBILE]: 'App',
    [CLOCKING_DEVICE.WEB]: 'Sito web',
    [CLOCKING_DEVICE.WEB_OR_MOBILE]: 'Web e App',
};

export const GROUPING_PERIODS = {
    WEEK: 'WEEK',
    MONTH: 'MONTH',
};
