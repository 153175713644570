/**
 * Normalizes the duration object by converting it to total seconds and then breaking it down into hours, minutes, and seconds.
 * Example: {hours: 0, minutes: 120, seconds: 0} => {hours: 2, minutes: 0, seconds: 0}
 *
 * @param {Object} duration - The duration object containing hours, minutes, and seconds.
 * @param {number} duration.hours - The number of hours in the duration.
 * @param {number} duration.minutes - The number of minutes in the duration.
 * @param {number} duration.seconds - The number of seconds in the duration.
 * @returns {Object} - The normalized duration object with hours, minutes, and seconds.
 */
function normalizeDuration(duration) {
    const {hours, minutes, seconds} = duration;
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;

    return {
        hours: Math.floor(totalSeconds / 3600),
        minutes: Math.floor((totalSeconds % 3600) / 60),
        seconds: totalSeconds % 60,
    };
}

export function durationFromTotalMinutes(totalMinutes) {
    const negative = totalMinutes < 0 ? -1 : 1;

    const absMinutes = Math.abs(totalMinutes);

    const hours = Math.floor(absMinutes / 60) * negative;
    const minutes = (absMinutes % 60) * negative;

    return {hours, minutes, seconds: 0};
}

/**
 * Parses a duration string in the format of "1h 30m 15s" and returns an object with hours, minutes, and seconds.
 *
 * @param {string} durationString - The duration string to parse.
 * @returns {object|null} - An object with hours, minutes, and seconds if the duration string is valid, otherwise null.
 */
export function parseDurationString(durationString) {
    if (durationString.trim() === '') {
        return {
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
    }

    const re = /((\d*)h)?((\d*)m)?((\d*)s)?/;

    const cleanedDurationString = durationString.replace(/ /g, '');

    if (!re.test(cleanedDurationString)) {
        return null;
    }

    const [, , hours, , minutes, , seconds] = cleanedDurationString.match(re);

    if (!hours && !minutes && !seconds) {
        return null;
    }

    return normalizeDuration({
        hours: hours ? Number(hours) : 0,
        minutes: minutes ? Number(minutes) : 0,
        seconds: seconds ? Number(seconds) : 0,
    });
}

/**
 * Formats the duration into a string representation.
 * Example {hours: 1, minutes: 30, seconds: 15} => "1h 30m 15s"
 *
 * @param {object} duration - The duration object.
 * @param {number} duration.hours - The number of hours.
 * @param {number} duration.minutes - The number of minutes.
 * @param {number} duration.seconds - The number of seconds.
 * @param {object} options - The formatting options.
 * @param {boolean} options.seconds - Whether to include seconds in the formatted string. Default is false.
 * @returns {string} The formatted duration string.
 */
export function formatDuration({hours, minutes, seconds}, {seconds: showSeconds = false} = {}) {
    const hoursString = hours !== 0 ? `${hours}h ` : '';
    const minutesString = minutes !== 0 ? `${minutes}m ` : '';
    const secondsString = showSeconds ? `${seconds}s` : '';

    return [hoursString, minutesString, secondsString].join('').trim();
}

/**
 * Parses a time interval string and returns a normalized duration object.
 * Example: 1:30:15 => {hours: 1, minutes: 30, seconds: 15}
 * Example: 1 2:00:00 => {hours: 26, minutes: 0, seconds: 0} (1 day and 2 hour)
 * In the second example we are converting the standard Django DurationField format
 *
 * @param {string} timeIntervalString - The time interval string to parse (e.g. "1:30:15").
 * @returns {object|null} - The normalized duration object or null if the parsing fails.
 */
export function parseTimeIntervalString(timeIntervalString) {
    let [hours, minutes, seconds] = timeIntervalString.trim().split(':');
    if (hours.includes(' ')) {
        const [days, hoursString] = hours.split(' ');
        hours = Number(days) * 24 + Number(hoursString);
    } else {
        hours = Number(hours);
    }
    minutes = minutes ? Number(minutes) : 0;
    seconds = seconds ? Number(seconds) : 0;

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
        return null;
    }

    return normalizeDuration({hours, minutes, seconds});
}

/**
 * Parses a time string and returns the parsed time object.
 * Example: 12:30:15 => {hours: 12, minutes: 30, seconds: 15}
 *
 * @param {string} timeString - The time string to be parsed.
 * @returns {object | null} - The parsed time object or null if the time string is invalid.
 */
export function parseTimeString(timeString) {
    const parsed = parseTimeIntervalString(timeString);

    if (!parsed) {
        return null;
    }

    const {hours, minutes, seconds} = parsed;

    if (hours > 23 || minutes > 59 || seconds > 59) {
        return null;
    }

    return parsed;
}

/**
 * Parses a time interval string and returns a normalized duration object.
 * Example: 08:00:00, 12:00:00 => {hours: 4, minutes: 0, seconds: 0}
 *
 * @param {string} timeIntervalFromString - The time interval string to start.
 * @param {string} timeIntervalToString - The time interval string to end.
 * @returns {object|null} - The normalized duration object or null if the parsing fails.
 */
export function getTimeIntervalDifference(timeIntervalFromString, timeIntervalToString) {
    const from = parseTimeIntervalString(timeIntervalFromString);
    const to = parseTimeIntervalString(timeIntervalToString);

    if (!from || !to) {
        return null;
    }

    const fromSeconds = from.hours * 3600 + from.minutes * 60 + from.seconds;
    const toSeconds = to.hours * 3600 + to.minutes * 60 + to.seconds;

    const difference = toSeconds - fromSeconds;

    return normalizeDuration({
        hours: Math.floor(difference / 3600),
        minutes: Math.floor((difference % 3600) / 60),
        seconds: difference % 60,
    });
}

/**
 * Formats a time interval string to a duration string.
 * Example: 1:30:15 => "1h 30m 15s"
 *
 * @param {string} timeIntervalString - The time interval string to format.
 * @param {Object} options - The formatting options.
 * @param {boolean} options.seconds - Whether to include seconds in the formatted duration. Default is false.
 * @returns {string} The formatted duration string.
 */
export function formatTimeIntervalToDuration(timeIntervalString, {seconds: showSeconds = false} = {}) {
    const parsed = parseTimeIntervalString(timeIntervalString);

    if (!parsed) {
        return '';
    }

    return formatDuration(parsed, {seconds: showSeconds});
}

/**
 * Formats a time interval into a string representation.
 * Example: {hours: 1, minutes: 30, seconds: 15} => "01:30:15"
 *
 * @param {Object} time - The time interval object.
 * @param {number} time.hours - The number of hours.
 * @param {number} time.minutes - The number of minutes.
 * @param {number} time.seconds - The number of seconds.
 * @param {Object} [options] - The formatting options.
 * @param {boolean} [options.seconds=true] - Whether to include seconds in the formatted string.
 * @returns {string} The formatted time interval string.
 */
export function formatTimeInterval({hours, minutes, seconds}, {seconds: showSeconds = true} = {}) {
    return `${`${hours}`.padStart(2, '0')}:${`${minutes}`.padStart(2, '0')}${showSeconds ? `:${`${seconds}`.padStart(2, '0')}` : ''}`;
}

/**
 * Converts decimal hours to sexagesimal format.
 * example: 1.5 => {hours: 1, minutes: 30, seconds: 0}
 *
 * @param {number} decimalHours - The decimal hours to be converted.
 * @returns {Object} - An object containing the hours, minutes, and seconds in sexagesimal format.
 */
export function decimalHoursToSexagesimal(decimalHours) {
    const hours = Math.floor(decimalHours);
    const minutes = Math.floor((decimalHours - hours) * 60);
    const seconds = Math.round((decimalHours - hours - minutes / 60) * 3600);

    return {hours, minutes, seconds};
}

/**
 * Converts the given value representing minutes since midnight to an object representing hours and minutes.
 * example: 90 => {hours: 1, minutes: 30}
 *
 * @param {number} value - The value representing minutes since midnight.
 * @returns {{hours: number, minutes: number}} - An object containing the hours and minutes.
 */
export function minutesSinceMidnightToTime(value) {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    return {hours, minutes};
}

/**
 * Removes the seconds component from a time interval string in "HH:MM:SS" format.
 *
 * @param {string} timeIntervalString - The time interval string to process, in "HH:MM:SS" format.
 * @returns {string} - The time interval string without seconds, in "HH:MM" format.
 *
 * @example
 * Example:
 * stripSecondsFromTimeInterval("12:30:45")
 * Returns: "12:30"
 */
export function stripSecondsFromTimeInterval(timeIntervalString) {
    return timeIntervalString.split(':').slice(0, 2).join(':');
}

export function millisecondsToNextMinute() {
    const now = new Date();
    const millisecondsInMinute = 60000;
    const elapsedMilliseconds = now.getSeconds() * 1000 + now.getMilliseconds();
    return millisecondsInMinute - elapsedMilliseconds;
}
