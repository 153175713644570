import {ApiBase} from '@jetCommon/api/base.js';

export default class AuthApiCommon extends ApiBase {
    static resourceName = 'auth';

    checkSpecialLinkCode(specialLinkCode) {
        return this.apiPost(
            'check_special_link/',
            {},
            {
                headers: {
                    ...this.getSpecialLinkCodeRequestHeader(specialLinkCode),
                },
            },
        );
    }

    logout(is_from_impersonation = false) {
        if (is_from_impersonation) {
            return this.apiPost('logout/', {is_from_impersonation});
        }
        return this.apiPost('logout/');
    }

    sendResetPasswordLink(email) {
        return this.apiPost('send_reset_password_link/', {email});
    }
}
