import axios, {AxiosError} from 'axios';
import {
    HTTP_4XX,
    HTTP_400_BAD_REQUEST,
    HTTP_401_UNAUTHORIZED,
    HTTP_403_FORBIDDEN,
    HTTP_404_NOT_FOUND,
    HTTP_5XX,
} from '@jetCommon/constants/http-status.js';
import {useJetBuildVersionStore} from '@jetCommon/stores/jet-build-version.js';
import {useJetMessage} from '@jetCommon/composables/jet-message.js';
import settings from './env.js';

const jetMessage = useJetMessage();

function getHttpStatusCodeClass(statusCode) {
    return Number(statusCode.toString().charAt(0));
}

export function renderApiError(errorResponse, skipMsgForCodes = [], msgDuration = 5000) {
    const errorCode = errorResponse.status;
    const errorCodeClass = getHttpStatusCodeClass(errorCode);

    if (skipMsgForCodes.includes(errorCode)) {
        return;
    }

    if (errorCodeClass === HTTP_4XX) {
        switch (errorCode) {
            case HTTP_400_BAD_REQUEST:
                for (const fieldName in errorResponse.data) {
                    const sendMessage = fieldName.startsWith('warning:') ? jetMessage.warning : jetMessage.error;

                    const handleNestedErrorsForField = data => {
                        const dataIsIterable = typeof data[Symbol.iterator] === 'function';
                        if (!dataIsIterable) {
                            Object.values(data).forEach(handleNestedErrorsForField);
                            return;
                        }
                        for (const error of data) {
                            sendMessage(error, {extraOptions: {duration: msgDuration}});
                        }
                    };

                    handleNestedErrorsForField(errorResponse.data[fieldName]);
                }
                break;
            case HTTP_401_UNAUTHORIZED:
            case HTTP_403_FORBIDDEN:
                jetMessage.error('Non hai i permessi necessari');
                break;
            case HTTP_404_NOT_FOUND:
                jetMessage.error('Risorsa non trovata');
                break;
            default:
                jetMessage.error(`Errore ${errorCode}`);
        }
    } else if (errorCodeClass === HTTP_5XX) {
        jetMessage.error('Problema temporaneo: riprova tra poco');
    }
}

export class PageNotFoundError extends Error {}

// This callable must be used as the only argument of the last .catch()
// in the API call chain to avoid console errors on API 4xx/5xx error statuses
// (implicitly returning a resolved promise with undefined value).
// It re-raises non-API-response exceptions so that Sentry can catch them.
export function end(error) {
    if (!error.response) {
        if (error.code === AxiosError.ERR_NETWORK) {
            jetMessage.error('Problema temporaneo: riprova tra poco');
            return;
        }

        throw error;
    }
}

const axiosInstance = axios.create({
    baseURL: settings.API_BASE_URL,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFTOKEN',
    withCredentials: true,
    withXSRFToken: true,
});

axiosInstance.interceptors.response.use(
    response => {
        useJetBuildVersionStore().updateBuildVersion(response.headers['x-jet-build-version']);
        return response;
    },
    error => {
        if (error.response) {
            useJetBuildVersionStore().updateBuildVersion(error.response.headers['x-jet-build-version']);
        }
        return Promise.reject(error);
    },
);

const abortControllers = {};
/**
 * Abort any pending requests with the same key and get signal from new AbortController
 *
 * @param {string} key - The key used to identify the requests to be aborted.
 * @return {AbortSignal} - The new signal that can be used to abort requests.
 */
function abortRequestsAndGetNewSignal(key) {
    if (abortControllers[key]) {
        abortControllers[key].abort();
    }
    abortControllers[key] = new AbortController();
    return abortControllers[key].signal;
}

export class GenericApiBase {
    // resourceName should be the name of the resource in backend endpoint
    static resourceName;
    static requestContentType = 'application/json';

    axios = null;
    static apiBasePath;

    constructor() {
        if (!this.constructor.resourceName) {
            throw new TypeError('resourceName not set!');
        }
        if (!this.constructor.apiBasePath) {
            throw new TypeError('apiBasePath not set!');
        }
        this.axios = axiosInstance;
    }

    getApiUrl(url, prependDomain = false) {
        const apiUrl = `${this.constructor.apiBasePath}${this.constructor.resourceName}/${url}`;
        return (prependDomain ? settings.API_BASE_URL : '') + apiUrl;
    }

    getApiUrlWithParams(url, params) {
        const fullUrl = this.getApiUrl(url, true);
        const searchParams = new URLSearchParams(params).toString();
        return `${fullUrl}?${searchParams}`;
    }

    getSpecialLinkCodeRequestHeader(specialLinkCode) {
        return {'SL-Code': specialLinkCode};
    }

    _handleApiError(error) {
        if (error.response) {
            // If Axios returned an error response
            renderApiError(error.response, this.skipMsgForCodes, this.msgDuration);
        }
        return Promise.reject(error);
    }

    noMsgForStatusCodes(excludedCodes = []) {
        return new Proxy(this, {
            get(target, prop) {
                if (prop === 'skipMsgForCodes') {
                    return excludedCodes;
                }
                return target[prop];
            },
        });
    }

    errorMsgDuration(duration = 5000) {
        return new Proxy(this, {
            get(target, prop) {
                if (prop === 'msgDuration') {
                    return duration;
                }
                return target[prop];
            },
        });
    }

    apiGet(url, config = {}) {
        const signal = config.abortKey && abortRequestsAndGetNewSignal(config.abortKey);
        return this.axios.get(this.getApiUrl(url), {...config, signal}).catch(error => this._handleApiError(error));
    }

    apiPost(url, data = {}, config = {}) {
        const signal = config.abortKey && abortRequestsAndGetNewSignal(config.abortKey);
        return this.axios
            .post(this.getApiUrl(url), data, {...config, signal})
            .catch(error => this._handleApiError(error));
    }

    apiPostForm(url, data = {}, config = {}) {
        const signal = config.abortKey && abortRequestsAndGetNewSignal(config.abortKey);
        return this.axios
            .postForm(this.getApiUrl(url), data, {...config, signal})
            .catch(error => this._handleApiError(error));
    }

    apiPatch(url, data = {}, config = {}) {
        const signal = config.abortKey && abortRequestsAndGetNewSignal(config.abortKey);
        return this.axios
            .patch(this.getApiUrl(url), data, {...config, signal})
            .catch(error => this._handleApiError(error));
    }

    apiDelete(url, config = {}) {
        const signal = config.abortKey && abortRequestsAndGetNewSignal(config.abortKey);
        return this.axios.delete(this.getApiUrl(url), {...config, signal}).catch(error => this._handleApiError(error));
    }

    empty() {
        return Promise.resolve([]);
    }

    emptyPaginated() {
        return Promise.resolve({
            results: [],
            count: 0,
            pages: 1,
        });
    }

    create(data, config = {}) {
        return this[this.constructor.requestContentType === 'multipart/form-data' ? 'apiPostForm' : 'apiPost'](
            '',
            data,
            config,
        );
    }

    list(params, config = {}, path = '') {
        return this.noMsgForStatusCodes([HTTP_404_NOT_FOUND])
            .apiGet(path, {...config, params})
            .catch(error => {
                if (error?.response?.status === HTTP_404_NOT_FOUND) {
                    // TODO: rename in something less ambiguous with 404:
                    // PageNumberNotFoundError? ResourceNotFoundError?
                    throw new PageNotFoundError();
                }
                throw error;
            });
    }

    getExportXlsUrl(params) {
        return this.getApiUrlWithParams('', {
            format: 'xlsx',
            scope: 'export',
            ...params,
        });
    }

    getExportableXlsFields(params = {}) {
        return this.apiGet('exportable_fields/', {
            params: {
                scope: 'export',
                ...params,
            },
        });
    }

    count(params, config = {}) {
        return this.apiGet('count/', {...config, params});
    }

    retrieve(id, params = {}, config = {}) {
        return this.apiGet(`${id}/`, {...config, params});
    }

    patch(id, data, config = {}) {
        return this.apiPatch(`${id}/`, data, config);
    }

    patchOrCreate(id, data, config = {}) {
        return id ? this.patch(id, data, config) : this.create(data, config);
    }

    delete(id, config = {}) {
        return this.apiDelete(`${id}/`, config);
    }
}
