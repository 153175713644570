/**
 * Calculates the distance between two geographical points using the Haversine formula.
 *
 * @param {Object} point1 - The first point.
 * @param {number} point1.latitude - The latitude of the first point in degrees.
 * @param {number} point1.longitude - The longitude of the first point in degrees.
 * @param {Object} point2 - The second point.
 * @param {number} point2.latitude- The latitude of the second point in degrees.
 * @param {number} point2.longitude - The longitude of the second point in degrees.
 * @returns {number} The distance between the two points in meters.
 */
export function distanceBetweenPoints({latitude: lat1, longitude: lon1}, {latitude: lat2, longitude: lon2}) {
    const R = 6371e3;
    const p1 = (lat1 * Math.PI) / 180;
    const p2 = (lat2 * Math.PI) / 180;
    const deltaP = p2 - p1;
    const deltaLon = lon2 - lon1;
    const deltaLambda = (deltaLon * Math.PI) / 180;
    const a =
        Math.sin(deltaP / 2) * Math.sin(deltaP / 2) +
        Math.cos(p1) * Math.cos(p2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
    const d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * R;
    return d;
}

/**
 * Formats a distance in meters to a human-readable string.
 *
 * @param {number} distanceMeters - The distance in meters.
 * @returns {string} The formatted distance.
 * @example
 * formatDistance(100); // '100 m'
 * formatDistance(1000); // '1,00 km'
 * formatDistance(1500); // '1,50 km'
 * formatDistance(15000); // '15,00 km'
 * formatDistance(150000); // '150,00 km'
 *
 *  */
export function formatDistance(distanceMeters) {
    if (distanceMeters < 1000) {
        return `${Math.round(distanceMeters)} m`;
    }
    return `${(distanceMeters / 1000).toFixed(2).replace('.', ',')} km`;
}
