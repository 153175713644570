import {ApiBase} from '@jetCommon/api/base.js';
import {dailyClockingSurrogateKey} from '@jetCommon/helpers/clocking.js';

export default class ClockingsApiCommon extends ApiBase {
    static resourceName = 'clockings';

    updateClockings(employeeId, companyId, date, clockings, note) {
        return this.apiPatch(`${dailyClockingSurrogateKey(employeeId, date)}/`, {company: companyId, clockings, note});
    }
}
