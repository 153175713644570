import {ApiBase} from '@jetCommon/api/base.js';

export default class ExpenseApiCommon extends ApiBase {
    static resourceName = 'expenses';

    async kindChoices(params) {
        return await this.apiGet('kind_choices/', {params});
    }

    async policiesKindChoices(params) {
        return await this.apiGet('policies_kind_choices/', {params});
    }

    async periodChoices(params) {
        return await this.apiGet('period_choices/', {params});
    }

    lastUsedCar(params) {
        return this.apiGet('last_used_car/', {params});
    }

    suggestExpenseData(data) {
        return this.apiPost('suggest_expense_data/', data);
    }

    companyExportFieldChoices(params) {
        return this.apiGet('company_export_field_choices/', {params});
    }

    getXlsxUrl(params) {
        return this.getApiUrlWithParams('download_xlsx/', params);
    }

    approve(id) {
        return this.apiPost(`${id}/approve/`);
    }

    reject(id, data) {
        return this.apiPost(`${id}/reject/`, data);
    }

    bulkApprove(expensesIds) {
        return this.apiPost('bulk_approve/', expensesIds);
    }
}
