import {ApiBase} from '@jetCommon/api/base.js';

export default class AbsenceCalendarApiCommon extends ApiBase {
    static resourceName = 'absence_calendars';

    getPersonalCalendar(companyId, config = {}) {
        return this.apiGet(`${companyId}/personal_absence_calendar/`, config);
    }

    getCompanyCalendar(companyId, config = {}) {
        return this.apiGet(`${companyId}/company_absence_calendar/`, config);
    }

    getCalendarSetup(companyId, config = {}) {
        return this.apiGet(`${companyId}/setup/`, config);
    }

    getCalendarManagersChoices(companyId, params) {
        return this.apiGet(`${companyId}/manager_choices/`, {params});
    }

    getCalendarEmployeeChoices(companyId, params) {
        return this.apiGet(`${companyId}/employee_choices/`, {params});
    }
}
